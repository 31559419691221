<template>
  <SubPageWrapper
    class="changes"
    width="full"
    relative
  >
    <template #loader>
      <SlOverlay
        :show="isLoading"
        filled
      />
    </template>
    <ContentBlock>
      <div class="subpage__title">
        <div class="heading-3-sb grey-100">
          {{ $t('Main.tabNames.changes') }}
        </div>
        <SlButton
          variant="secondary"
          color="grey"
          :disabled="isExportDisabled"
          @click="handleExportToXLSX"
        >
          {{ $t('Main.Ui.acChooseExportToXlsx') }}
        </SlButton>
      </div>

      <div class="subpage__table">
        <SlTable
          id="changes-table"
          :headers="tableHeaders"
          :rows="tableRows"
          unique-key="id"
          header-unique-key="key"
          header-name-key="name"
          header-type-key="type"
          :max-height="1100"
          :initial-col-sizes="[185, 240, 970]"
          pagination
          :row-height="40"
          :total-rows-count="totalRowsCount"
          :current-page="currentPage"
          :per-page="perPage"
          :value-parser="parseValue"
          num-col
          list
          @page-change="handlePaginationChange"
          @per-page-change="handlePerPageChange"
          @contextmenu:clipboard="(cb) => cb()"
        >
          <template #author="{ cellValue }">
            <div
              v-if="cellValue.val"
              class="sl-table__cell-text"
            >
              <div class="changes-author__cell">
                <SlAvatar
                  class="changes-author__image"
                  :size="20"
                  :url="cellValue.avatarUrl"
                  :name="cellValue.val"
                />
                {{ cellValue.val }}
              </div>
            </div>
          </template>
          <template #timestamp="{ cellValue, text }">
            <div
              :title="text"
              class="sl-table__cell-text"
            >
              {{ getTimeValue(cellValue) }}
            </div>
          </template>
        </SlTable>
      </div>
    </ContentBlock>
  </SubPageWrapper>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import SubPageWrapper from '@/components/Shared/Page/SubPageWrapper';
import { fileSaver } from '@/mixins/webAPI';
import { tableConfig } from '@/mixins/tableConfig';
import { componentNames } from '@/config/app.config.js';
import { PAGE_SIZE } from '@/config/report';
import { sendRequests } from '@/helpers/lastChange/sendRequests.js';
import { dateByLocaleKey, localeDateKeys } from '@/helpers/locale/localeDate';

export default {
  name: 'Changes',
  components: {
    SubPageWrapper
  },
  mixins: [fileSaver, tableConfig],
  data() {
    return {
      PAGE_SIZE,
      isLoading: false
    };
  },
  computed: {
    ...mapState({
      table: state => state.changes.table,
      tableConfig: state => state.changes.tableConfig
    }),
    rowLength() {
      return this.table?.rows?.length;
    },
    totalRowsCount() {
      return this.table?.totalRowsCount;
    },
    currentPage() {
      return this.table?.currentPage;
    },
    isPaginationVisible() {
      return this.rowLength && this.totalRowsCount > this.rowLength;
    },
    tableHeaders() {
      return this.table?.headers || [];
    },
    tableRows() {
      return this.table?.rows || [];
    },
    isExportDisabled() {
      return !this.tableRows.length;
    }
  },
  async beforeMount() {
    try {
      this.isLoading = true;

      await sendRequests(componentNames.CHANGES);
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    ...mapActions({
      fetchChanges: 'changes/fetchChanges',
      exportChanges: 'changes/exportChanges',
      updateTableConfig: 'changes/updateTableConfig'
    }),
    async fetchTable() {
      try {
        this.isLoading = true;

        await this.fetchChanges();
      } finally {
        this.isLoading = false;
      }
    },
    parseValue(cellValue) {
      if (!cellValue) {
        return '';
      }

      if (typeof cellValue !== 'object') {
        return cellValue;
      }

      return cellValue.val || cellValue || '';
    },
    getTimeValue(cellValue) {
      const [date, time] = cellValue?.split(' ');

      return `${dateByLocaleKey(date, localeDateKeys.YMD)} ${time}`;
    },
    async handleExportToXLSX() {
      this.saveFile(this.exportChanges.bind(this));
    },
    handlePaginationChange(page) {
      this.page = page;

      this.fetchTable();
    },
    handlePerPageChange(pageSize) {
      this.perPage = pageSize;
      this.page = 1;

      this.fetchTable();
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/changes/index.scss";
</style>
